import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Games from "./Const/Games";
import Spinner from "./Spiner";

// Function to get random games
const getRandomGames = (games, count) => {
  const shuffled = [...games].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

const Play = () => {
  const [searchParams] = useSearchParams();
  const link = searchParams.get("play");
  const [loading, setLoading] = useState(true);
  const [selectedGames, setSelectedGames] = useState([]);
  const [leftGames, setLeftGames] = useState([]);
  const [rightGames, setRightGames] = useState([]);
  const [iframeLoading, setIframeLoading] = useState(false);
  const [currentGame, setCurrentGame] = useState(null); // State to hold the current game
  const iframeRef = useRef(null); // Ref for the iframe
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      const games = getRandomGames(Games, 12); // Get 12 random games
      setSelectedGames(games);
      setLeftGames(games.slice(0, 3)); // Get 3 games for left column
      setRightGames(games.slice(3, 6)); // Get 3 games for right column
      setLoading(false);
      setIframeLoading(false);
    }, 500); // 5 seconds

    // Clean up the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Update current game when the link changes
    if (link) {
      const selectedGame = Games.find((game) => game.gameurl === link);
      setCurrentGame(selectedGame || null); // Find the current game by URL
    }
  }, [link]);

  const handleGameClick = (gameLink) => {
    setIframeLoading(true); // Show loader when the game is clicked
    navigate(`/play?play=${gameLink}`);
  };

  useEffect(() => {
    // When link changes, simulate iframe loading
    if (link) {
      setIframeLoading(true);
      const iframe = iframeRef.current;
      if (iframe) {
        iframe.onload = () => {
          setIframeLoading(false);
        };
      }
    }
  }, [link]);

  return (
    <section className="p-8 bg-gray-100 min-h-screen flex flex-col items-center">
      {loading ? (
        <div className="flex items-center justify-center w-full h-[100vh]">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-12 gap-5 w-full max-w-[107rem]">
            <div className="hidden lg:block lg:col-span-3 md:col-span-1">
              {leftGames.map((game, index) => (
                <div
                  key={index}
                  onClick={() => handleGameClick(game.gameurl)} // Handle game card click
                  className={`cursor-pointer hover:border-red-50 rounded-lg shadow-lg border-2 transition-all relative w-full overflow-hidden mb-5`}
                  style={{ height: `calc(60vh / 3.5)` }} // Apply height dynamically
                >
                  <img
                    src={game.image}
                    alt={game.title}
                    className="h-full w-full object-cover rounded-t-lg" // Adjust image height
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 text-white text-lg font-bold p-3">
                    {game.title}
                  </div>
                </div>
              ))}
            </div>

            <div className="relative col-span-12 lg:col-span-6 rounded-lg shadow-lg w-full">
              {iframeLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-70 z-10">
                  <Spinner />
                </div>
              )}
              <iframe
                src={link || ""} // Handle the case where link might be null
                title="Play Screen"
                className="w-full md:h-[60vh] h-[80vh]" // Adjust iframe height
                frameBorder="0"
                ref={iframeRef}
              ></iframe>
            </div>

            <div className="hidden lg:block lg:col-span-3 md:col-span-1">
              {rightGames.map((game, index) => (
                <div
                  key={index}
                  style={{ height: `calc(60vh / 3.5)` }} // Apply height dynamically
                  onClick={() => handleGameClick(game.gameurl)} // Handle game card click
                  className={`cursor-pointer hover:border-red-50 rounded-lg shadow-lg border-2 transition-all relative w-full overflow-hidden mb-5`}
                >
                  <img
                    src={game.image}
                    alt={game.title}
                    className="h-full w-full object-cover rounded-t-lg" // Adjust image height
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 text-white text-lg font-bold p-3">
                    {game.title}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex items-center flex-col w-full max-w-7xl mx-auto">
            {currentGame && (
              <div className="mt-4 p-4 bg-white rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-2">{currentGame.title}</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: currentGame.description }}
                ></div>
                <div
                  className="md:block hidden"
                  dangerouslySetInnerHTML={{ __html: currentGame.sec_desc }}
                ></div>
              </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8 w-full">
              {selectedGames.map((game, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => handleGameClick(game.gameurl)} // Handle game card click
                    className={`bg-white cursor-pointer  rounded-lg shadow-lg border-2 transition-all relative overflow-hidden`}
                  >
                    <img
                      src={game.image}
                      alt={game.title}
                      className="w-full h-40 object-cover rounded-t-lg" // Adjust image height
                    />
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 text-white text-lg font-bold p-3">
                      {game.title}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </section>
  );
};
export default Play;
