import React, { useState, useEffect } from "react";
import Games from "./Const/Games";
import Spinner from "./Spiner";
import { Link } from "react-router-dom";

// Helper function to get random games
const getRandomGames = (games, count) => {
  const shuffled = [...games].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

// Helper function to generate random Tailwind gradients
const getRandomTailwindGradient = () => {
  const gradients = [
    "bg-gradient-to-r from-purple-400 via-pink-500 to-red-500",
    "bg-gradient-to-r from-green-400 to-blue-500",
    "bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500",
    "bg-gradient-to-r from-red-400 to-yellow-500",
    "bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500",
    "bg-gradient-to-r from-indigo-400 via-blue-500 to-green-500",
    "bg-gradient-to-r from-blue-400 to-teal-500",
    "bg-gradient-to-r from-teal-400 to-sky-500",
    "bg-gradient-to-r from-sky-400 to-blue-500",
    "bg-gradient-to-r from-blue-400 via-teal-500 to-sky-600",
    "bg-gradient-to-r from-teal-400 via-sky-500 to-blue-600",
  ];
  return gradients[Math.floor(Math.random() * gradients.length)];
};

const HomePage = () => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      const selectedGames = getRandomGames(Games, 12);
      setGames(selectedGames);
      setLoading(false);
    }, 800); // 3000 milliseconds (3 seconds)

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  return (
    <section className="p-8 bg-gray-100 min-h-screen flex items-center justify-center">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <Spinner />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full max-w-7xl">
          {games.map((game, index) => {
            const gradient = getRandomTailwindGradient();
            return (
              <div
                key={index}
                className={`bg-white rounded-lg shadow-lg w-full relative overflow-hidden transition-transform transform hover:scale-105 hover:shadow-2xl hover:translate-y-[-10px] duration-500`}
              >
                <img
                  src={game.image}
                  alt={`Featured ${game.title}`}
                  className="w-full h-64 object-cover rounded-t-lg transition-transform transform group-hover:scale-110 duration-300"
                />
                <div className="p-6 pb-0">
                  <h2 className="text-xl font-semibold mb-4 text-gray-800 transition-colors duration-300 group-hover:text-blue-600">
                    {game.title}
                  </h2>
                </div>
                <div className="p-5 pt-0">
                  <Link
                    to={`/play?play=${game?.gameurl}`}
                    className={`text-center block w-full ${gradient} text-white px-4 py-2 rounded-full hover:opacity-80 transition-opacity duration-300`}
                  >
                    <svg
                      className="w-6 h-6 inline-block mr-2 mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 3l14 9-14 9V3z"></path>
                    </svg>
                    Play
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default HomePage;
