const Spinner = () => (
  <div className="flex items-center justify-center h-full">
    <div className="relative flex items-center">
      <div className="w-4 h-4 bg-gray-800 rounded-full absolute animate-ping" />
      <div className="w-4 h-4 bg-gray-800 rounded-full absolute animate-ping delay-200" />
      <div className="w-4 h-4 bg-gray-800 rounded-full absolute animate-ping delay-400" />
    </div>
  </div>
);


export default Spinner;
