import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="bg-gray-800 text-white py-8 mt-8">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        {/* Card Section */}
        <div className="bg-white text-gray-800 rounded-lg shadow-lg p-6 mb-6 md:mb-0 md:w-1/3">
          <h3 className="text-xl font-semibold mb-2">Welcome to Our Site</h3>
          <p className="mb-4">
            Discover more about us and what we offer by exploring the links
            below.
          </p>
          <NavLink
            to="/"
            className={({ isActive }) =>
              `text-blue-600 hover:underline ${
                isActive ? "font-bold text-blue-800" : ""
              }`
            }
            onClick={scrollToTop}
          >
            Go to Home Page
          </NavLink>
        </div>

        {/* Links Section */}
        <div className="flex flex-col md:flex-row md:space-x-8">
          <div className="mb-4 md:mb-0">
            <NavLink
              to="/about"
              className={({ isActive }) =>
                `hover:underline ${
                  isActive ? "text-blue-400 font-bold" : "text-white"
                }`
              }
              onClick={scrollToTop}
            >
              About Us
            </NavLink>
          </div>
          <div className="mb-4 md:mb-0">
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                `hover:underline ${
                  isActive ? "text-blue-400 font-bold" : "text-white"
                }`
              }
              onClick={scrollToTop}
            >
              Contact Us
            </NavLink>
          </div>
          <div className="mb-4 md:mb-0">
            <NavLink
              to="/term&condition"
              className={({ isActive }) =>
                `hover:underline ${
                  isActive ? "text-blue-400 font-bold" : "text-white"
                }`
              }
              onClick={scrollToTop}
            >
              Terms and Conditions
            </NavLink>
          </div>
          <div>
            <NavLink
              to="/privacy"
              className={({ isActive }) =>
                `hover:underline ${
                  isActive ? "text-blue-400 font-bold" : "text-white"
                }`
              }
              onClick={scrollToTop}
            >
              Privacy Policy
            </NavLink>
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <p>&copy; {new Date().getFullYear()} Game Shop. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
