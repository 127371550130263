import React from "react";
import { Link } from "react-router-dom";
import logo from "../Assets/3 (1).png";

const Header = () => {
  return (
    <header className="bg-gray-800 text-white p-4 shadow-md">
      <Link
        to={"/"}
        className="container mx-auto flex justify-between items-center"
      >
        <img src={logo} alt="" className="w-[150px]" />
      </Link>
    </header>
  );
};

export default Header;
