const About = () => (
  <main className="p-4 mt-5">
    <div className="container mx-auto">
      <div>
        <h1 className="text-3xl font-bold mb-4">About Us</h1>
        <p className="text-gray-700">Welcome to HTML5Game.Shop!</p>
        <p>
          At HTML5Game.Shop, we are dedicated to providing a platform where
          gaming enthusiasts can discover, purchase, and enjoy the best HTML5
          games available. Our mission is to offer a diverse selection of
          high-quality games that can be played seamlessly on any device,
          anytime, anywhere.
        </p>
      </div>
      <div className="pt-4">
        <h2 className="font-bold">Who We Are</h2>
        <p>
          We are a team of passionate gamers, developers, and designers who
          believe in the power of HTML5 technology to revolutionize the gaming
          experience. With our combined expertise, we strive to bring you an
          unparalleled gaming marketplace that caters to all your gaming needs.
        </p>
      </div>
      <div className="pt-4">
        <h2 className="font-bold">What We Offer</h2>
        <ul class="list-disc list-inside	">
          <li>
            Extensive Game Library: Browse through our vast collection of HTML5
            games across various genres, including action, adventure, puzzle,
            strategy, and more.
          </li>
          <li>
            Quality Assurance: Every game in our store is carefully curated and
            tested to ensure it meets our high standards of quality and
            performance.
          </li>
          <li>
            Instant Access: Enjoy instant access to all your purchased games,
            playable directly in your web browser without the need for downloads
            or installations.
          </li>
          <li>
            Exclusive Deals: Take advantage of our exclusive deals and
            discounts, ensuring you get the best value for your money.
          </li>
          <li>
            Customer Support: Our dedicated customer support team is always here
            to assist you with any questions or issues you may have.
          </li>
        </ul>
      </div>
      <div className="pt-4">
        <h2 className="font-bold">Our Vision</h2>
        <p>
          Our vision at HTML5Game.Shop is to become the premier destination for
          HTML5 game enthusiasts worldwide. We aim to create a vibrant community
          of gamers who can discover new favorites, share their experiences, and
          connect with others who share their passion for gaming.
        </p>
      </div>
      <div className="pt-4">
        <h2 className="font-bold">Our Commitment</h2>
        <ul class="list-disc list-inside	">
          <li>
            Innovation: We continuously seek to innovate and enhance our
            platform, ensuring you have access to the latest and greatest HTML5
            games.
          </li>
          <li>
            Community: We are committed to fostering a welcoming and inclusive
            community where all gamers feel valued and supported.
          </li>
          <li>
            Transparency: We believe in maintaining transparency in all our
            operations, from game listings to customer transactions.
          </li>
        </ul>
      </div>
      <div className="pt-4">
        <h2 className="font-bold">Get In Touch</h2>
        <p>
          We love hearing from our community! If you have any questions,
          feedback, or just want to chat about gaming, feel free to reach out to
          us at contact@html5game.shop.
        </p>
        <p>Thank you for visiting HTML5Game.Shop. Happy gaming!</p>
      </div>
    </div>
  </main>
);
export default About;
